import router from './router'
import store from './store'
import VueCookeis from 'vue-cookies'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import { constantRouterMap, staticRouterMap } from '@/config/router.config'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

function isNoSecretRouter (routerArr, path) {
  return routerArr.reduce((result, item) => {
    if (result) return result
    if (item.path === path) {
      return true
    }
    if (item.children) {
      return isNoSecretRouter(item.children, path)
    }
    return false
  }, false)
}

router.beforeEach(async (to, from, next) => {
  NProgress.start() // start progress bar
  // zc__sdk__sys__btnbox
  to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${domTitle}`)
  var userAllRoute = [...constantRouterMap, ...staticRouterMap, ...store.getters.addRouters]
  if (VueCookeis.get(ACCESS_TOKEN)) {
    if (!store.getters.userInfo) {
      await store.dispatch('GetInfo')
    }
    if (!store.getters.addRouters.length) {
      const routers = await store.dispatch('GenerateRoutes')
      userAllRoute.push(...routers)
    }
    if (to.matched[to.matched.length - 1].path === to.path && !isNoSecretRouter(userAllRoute, to.path)) {
      next({ path: '/403' })
    } else {
      if (store.getters.isVip) {
        next()
      } else {
        if (isNoSecretRouter(constantRouterMap, to.path)) {
          next()
        } else {
          next({ path: '/home' })
        }
      }
    }
  } else {
    if (to.path === '/403' || to.path === '/404') {
      next({ path: '/home' })
    } else if (to.matched[to.matched.length - 1].path === to.path && !isNoSecretRouter(userAllRoute, to.path)) {
      next({ path: '/home' })
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
